<template>
    <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden">
        <div class="card-body p-lg-4 bg-white">
            <form @submit.prevent="onSubmit()" class="row justify-content-center">
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Full Name<br><small>Nama Lengkap</small></label>
                    <input type="text" v-model="fullname" class="form-control" placeholder="Insert your Full Name" required>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">ID Number<br><small>No. KTP/SIM/Paspor</small></label>
                    <input type="number" v-model="id_no" class="form-control" placeholder="Insert your ID Number" required>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Information Source<br><small>Sumber Informasi</small></label>
                    <select v-model="partner_id" required class="form-control">
                        <option value="" selected disabled>-- Select Information Source --</option>
                        <option :value="item.id" v-for="(item, index) in partner" :key="index">
                            <span>
                            {{ item.title }}
                            </span>
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Class Progam<br><small>Jenis Program</small></label>
                    <select v-model="program" required class="form-control">
                        <option value="" selected disabled>-- Select Your Class Program --</option>
                        <option :value="item" v-for="(item, index) in programs" :key="index">
                            <span>
                            {{ item.name }}
                            </span>
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Class Period<br><small>Periode Program</small></label>
                    <select v-model="batch" required class="form-control">
                        <option value="" selected disabled>-- Select Your Class Period --</option>
                        <option :value="item" v-for="(item, index) in batches" :key="index">
                            <span>
                            {{ item.name }}
                            </span>
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Date of Birth<br><small>Tanggal Lahir</small></label>
                    <input type="date" v-model="birth_date" class="form-control" placeholder="Insert your Date of Birth" required>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Gender<br><small>Jenis Kelamin</small></label>
                    <select v-model="sex" class="form-control" required>
                        <option value="" selected disabled>-- Select your Gender --</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                    </select>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Mobile Number<br><small>Nomor Handphone</small></label>
                    <input type="tel" min="11" v-model="no_phone" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" required class="form-control" placeholder="Your Phone Number" >
                </div>
                <div class="form-group col-12">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input mt-2" v-model="check1" required>
                        <label class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck1">
                            Make sure your data was correct
                            <br><small>Pastikan seluruh data sudah benar</small>
                        </label>
                    </div>
                </div>
                <div class="form-group col-lg-12 text-right mt-2" v-if="!process">
                    <button class="btn btn-current" type="submit" :disabled="!check1 || !batch">Submit</button>
                </div>
                <div class="form-group col-lg-12 text-right mt-2" v-else>
                    <button class="btn btn-current" disabled>Processing...</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
export default {
    data() {
        return {
            
            //confirm: false,
            check1: false,
            process: false,
            testdate: [],
            partner: [],
            programs: [],
            program: '',
            batches: [],
            batch: '',
            fullname: '',
            id_no: '',
            partner_id: '',
            birth_date: '',
            sex: '',
            no_phone: '',
        }
    },
    created(){
        this.getPartner()
        this.getClassPrograms()
    },
    watch: {
        program:function(){
            this.getClassProgramBatches()
        }
    },
    methods: {
        getPartner() {
            axios.get(`${process.env.VUE_APP_URL_API}/core/toefl/partner`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.partner = res.data
            })
        },
        getClassPrograms() {
            axios.get(`${process.env.VUE_APP_URL_API}/core/class-registration/program`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.programs = res.data.data
            })
        },
        getClassProgramBatches() {
            this.batches = []
            this.batch = ''
            console.log('MASUK', this.program)
            if(this.program?.id){
                console.log('MASUK2', this.program)
                axios.get(`${process.env.VUE_APP_URL_API}/core/class-registration/batch?program_id=${this.program.id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.batches = res.data.data
                })
            }
        },
        formatDate(date) {
            return moment(date).format('MMMM DD, YYYY');
        },
        async onSubmit() {
            this.process = true
            const payload = {
                fullname: this.fullname,
                id_no: this.id_no,
                partner_id: this.partner_id,
                program_id: this.program.id,
                batch_id: this.batch.id,
                birth_date: this.birth_date, 
                sex: this.sex,
                phone_number: this.no_phone
            }
            console.log(payload)

            await axios.post(`${process.env.VUE_APP_URL_API}/core/class-registration`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                console.log('success', res.data)
                this.$swal({
                    toast: true,
                    title: 'Class Registration',
                    text: 'Redirecting you to payment',
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                window.location.href = "https://" + res.data.data.payment_url
            }).catch(err => {
                console.log('error', err.response)
                this.$swal({
                        toast: true,
                        title: 'Class Registration',
                        text: 'Failed register!',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
            }).finally(() => this.process = false)
        }
    }
}
</script>