<template>
    <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden">
        <div class="card-body p-lg-4 bg-white">
            <div class="row justify-content-center">
                <div v-if="!isLoad" class="col-12 py-3">
                    <div class="table-responsive mw-100 pr-2 ml-0">
                        <datatable :class="'table table-hover table-bordered'" :columns="columns" :data="history" :page="1" :perPage="10">
                            <template name="default" slot-scope="{ row, index }">
                                <tr v-if="row">
                                    <td class="text-center">{{ index+1 }}</td>
                                    <td>{{ row.class_registration.fullname }}</td>
                                    <td>{{ row.class_registration.program.name }}</td>
                                    <td>{{ row.class_registration.batch.name  }}</td>
                                    <td>{{ row.transaction_date }}</td>
                                    <td>{{ row.status }}</td>
                                    <td>
                                        <div v-if="row.status == 'PENDING'">
                                            <button @click="payNow('https://' + row.class_registration.payment_url )" class="btn btn-current" type="submit">Pay Now</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="8">Nothing to see here</td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                </div>
                <div v-else class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            history: [],
            isLoad: true,
            page: 1,
            tableFilter: '',
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Full Name', field: 'fullname', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Program', field: 'id_no', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Batch', field: 'partner_id', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Registration Date', field: 'category_test', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Status', field: 'test_date', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: '', field: 'country', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},                                                   
            ]
        }
    },
    created() {
        this.getHistory()
    },
    methods: {
        payNow(url){
            window.location.href = url
        },
        async getHistory() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/class-registration`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.history = res.data.data
                console.log('HISTORY', res.data.data)
                this.isLoad = false
            }).catch(err => {
                if (err?.response?.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Class Registration!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Class Registration!',
                        text: 'Failed get history!',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.process = false)
            console.log(history);
        }
    }
}
</script>